import { API_ENUM_VIEW_WIDGET, API_KEY_VIEW_WIDGET_IS_REQUIRED, API_KEY_VIEW_WIDGET_IS_VIEW, } from "api/const";


export function initViewField(field) {
    let isView = false;

    switch (field) {
        case undefined:
        case null:
        case API_ENUM_VIEW_WIDGET.NOREQUIRED:
            break;
        case API_ENUM_VIEW_WIDGET.REQUIRED:
        case API_ENUM_VIEW_WIDGET.EMPTY:
        case API_ENUM_VIEW_WIDGET.OPTIONAL:
            {
                isView = true;
                break;
            }
        default:
            break;
    }
    return isView;
}

export function initRequiredField(field) {
    let isRequired = false;

    switch (field) {
        case undefined:
        case null:
        case API_ENUM_VIEW_WIDGET.OPTIONAL:
        case API_ENUM_VIEW_WIDGET.NOREQUIRED:
            break;
        case API_ENUM_VIEW_WIDGET.REQUIRED:
        case API_ENUM_VIEW_WIDGET.EMPTY:
            {
                isRequired = true;
                break;
            }
        default:
            break;
    }
    return isRequired;
}

export function initViewRequiredField(field) {
    return {
        [API_KEY_VIEW_WIDGET_IS_VIEW]: initViewField(field),
        [API_KEY_VIEW_WIDGET_IS_REQUIRED]: initRequiredField(field)
    };
}