
const d = {
    'false': false,
    '': false,
    'true': true,
}

export function getBool(str) {
    if (str === null || str === undefined)
        return false;

    return d[str.toLowerCase()] || false;
}