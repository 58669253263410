import React from 'react'
import { Grid } from '@mui/material'
// app
import {
    API_KEY_OFF_BUTTON_AGAIN_REGISTRATION,
    API_KEY_TEXT_END_REGISTRATION,
    API_STATUS_REGISTRATION,
} from 'api/const'
import ErrorRegistration from 'status/ErrorRegistration';
import SuccessRegistration from 'status/SuccessRegistration';
import useTranslationWithCapitalization from 'utils/useTranslationWithCapitalization';
import ButtonAgainRegistration from 'button/ButtonAgainRegistration';
import TypographyEndRegisrtation from 'common/TypographyEndRegisrtation';

function EndRegistration({ statusRegistration, onClick, dataSetMap, }) {
    const tc = useTranslationWithCapitalization();

    const isSuccess = statusRegistration === API_STATUS_REGISTRATION.SUCCESS;
    const isError = statusRegistration === API_STATUS_REGISTRATION.ERROR;
    const isClose = statusRegistration === API_STATUS_REGISTRATION.CLOSE;

    const errRegistration = tc("registration error")
    const errCloseRegistration = tc("registration is closed")

    // text end registration
    const textEndRegistration = dataSetMap[API_KEY_TEXT_END_REGISTRATION];
    const isTextEndRegisration = textEndRegistration !== null;

    // off button again registration
    const isOffButtonAgainRegistration = dataSetMap[API_KEY_OFF_BUTTON_AGAIN_REGISTRATION];

    return (
        <Grid
            container
            sx={{ my: 1 }}
        >
            {/* message STATUS registration */}
            <Grid item xs={12}>
                {isSuccess ? <SuccessRegistration /> : null}
                {isError ? <ErrorRegistration text={errRegistration} /> : null}
                {isClose ? <ErrorRegistration text={errCloseRegistration} /> : null}
            </Grid>

            {/* print text end registration */}
            {isTextEndRegisration ? <TypographyEndRegisrtation textEndRegistration={textEndRegistration} /> : null}

            {/* button again registration */}
            {!isOffButtonAgainRegistration ? <ButtonAgainRegistration onClick={onClick} isClose={isClose} /> : null}

        </Grid>
    )
}

export default EndRegistration