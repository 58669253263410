import React from 'react'
// react-hook-form
import { useController } from 'react-hook-form'
// MUI
import {
    Grid,
    FormHelperText,
    FormControl,
    TextField,
} from '@mui/material'
import { WIDGET_CLASS_NAME_TEXT_FIELD } from 'api/const';
// phone
import { useActualPhoneInput } from 'syled/StyledPhoneInput';
import { usePrimaryColor } from 'syled/usePrimaryColor';

function InputPhoneField({ nameField, control, labels, isViewField = false, isRequaredField = false }) {
    const {
        field: { onChange, name, value, ref },
        fieldState: { error },
    } = useController({
        name: nameField,
        control,
    });

    const ActualPhoneInput = useActualPhoneInput()
    const primaryColor = usePrimaryColor()

    if (!isViewField)
        return null

    const label = labels ? labels[nameField] || "" : "";

    // fix empty phone number
    if ("" === value)
        onChange(null)

    return (
        <Grid item xs={12}>
            <FormControl
                fullWidth
                error={!!error}
            >
                <ActualPhoneInput
                    primaryColor={primaryColor}
                    className={WIDGET_CLASS_NAME_TEXT_FIELD}
                    label={label + (isRequaredField ? "*" : "")}

                    onChange={onChange}
                    value={value || null} // input value

                    name={name} // send down the input name
                    inputRef={ref} // send input ref, so we can focus on input when error appear
                    country={"ru"}
                    regions={['europe']}
                    component={TextField}
                    inputProps={{
                        error: !!error,
                    }}
                />
                {!!error ? <FormHelperText>{error.message}</FormHelperText> : null}
            </FormControl>
        </Grid>
    );
}

export default InputPhoneField;