import React from 'react'
import { Stack } from '@mui/material'

function StackRow({ children, ...rest }) {
    return (
        <Stack
            direction={"row"}
            spacing={1}
            sx={{
                display: "flex",
                alignItems: "center",
            }}
            maxWidth={'100%'}
            {...rest}
        >
            {children}
        </Stack>
    )
}

export default StackRow