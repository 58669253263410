import React from 'react'
// redux
// react-hook-form
import { useController } from 'react-hook-form'
// MUI
import {
    Grid2,
} from '@mui/material'
import { WIDGET_CLASS_NAME_TEXT_FIELD } from 'api/const';
import { useActualTextField } from 'syled/StyledTextField';
import { usePrimaryColor } from 'syled/usePrimaryColor';

const toUpperCase = (value) => value.trimLeft().toUpperCase();
const toLowerCase = (value) => value.trimLeft().toLowerCase();
const toNormalCase = (value) => value.trimLeft();
const noTransform = (value) => value

function InputTextField({
    nameField, control, labels,
    autoFocus = false,
    isViewField = false,
    isRequaredField = false,
    columns = 12,
    isLowerCase = true, isUpperCase = false, isNormalCase = false,
    noTransformCase = false,
    isDisabled = false
}) {
    const {
        field: { onChange, onBlur, name, value, ref },
        fieldState: { error },
    } = useController({
        name: nameField,
        control,
    });

    const ActualTextField = useActualTextField()
    const primaryColor = usePrimaryColor()

    if (!isViewField)
        return null;

    const label = labels ? labels[nameField] || "" : "";

    let transformValue = toNormalCase;
    if (isLowerCase) {
        transformValue = toLowerCase;
    }
    if (isUpperCase) {
        transformValue = toUpperCase;
    }
    if (isNormalCase) {
        transformValue = toNormalCase;
    }
    if (noTransformCase === true) {
        transformValue = noTransform
    }


    return (
        <Grid2
            item
            size={{ xs: columns }}
            sx={{
                mb: 1
            }}
        >
            <ActualTextField
                primaryColor={primaryColor}
                fullWidth
                className={WIDGET_CLASS_NAME_TEXT_FIELD}
                disabled={isDisabled}
                autoFocus={autoFocus}
                label={label + (isRequaredField ? "*" : "")}
                onChange={(e) => {
                    e.target.value = transformValue(e.target.value);
                    onChange(e)
                }} // send value to hook form
                onBlur={onBlur} // notify when input is touched/blur
                value={value || ""} // input value
                name={name} // send down the input name
                inputRef={ref} // send input ref, so we can focus on input when error appear
                // error
                error={!!error}
                helperText={error ? error.message : ""}
                autoComplete={"off"}
            />
        </Grid2>
    );
}

export default InputTextField;
