import { Grid2 } from '@mui/material'
import { WIDGET_CLASS_NAME_BUTTON } from 'api/const';
import React from 'react'
import ReplayIcon from '@mui/icons-material/Replay';
import { useActualButton } from 'syled/StyledButton'
import { usePrimaryColor } from 'syled/usePrimaryColor'
import useTranslationWithCapitalization from 'utils/useTranslationWithCapitalization';

function ButtonAgainRegistration({ onClick, isClose }) {
    const tc = useTranslationWithCapitalization();
    const nameButton = tc("register again")

    const ActualButton = useActualButton()
    const primaryColor = usePrimaryColor()
    return (
        <Grid2
            size={{ xs: 12 }}
        >
            <ActualButton
                primaryColor={primaryColor}
                fullWidth
                disabled={isClose}
                className={WIDGET_CLASS_NAME_BUTTON}
                variant="contained"
                color="success"
                startIcon={<ReplayIcon />}
                onClick={onClick}>
                {
                    nameButton
                }
            </ActualButton>
        </Grid2>
    )
}

export default ButtonAgainRegistration