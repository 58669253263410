import {
    TextField,
    styled,
} from '@mui/material'
import { usePrimaryColor } from './usePrimaryColor';


export const StyledTextField = styled(TextField)(({ primaryColor }) => {   return {
    '& label.Mui-focused': {
      color: `${primaryColor}`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${primaryColor}`,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `${primaryColor}`,
      },
      '&:hover fieldset': {
        borderColor: `${primaryColor}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `${primaryColor}`,
      },
    },
  }
});

export const useActualTextField = () => {

    const primaryColor = usePrimaryColor()
    const ActualTextField = primaryColor ? StyledTextField : TextField
    return ActualTextField
}
