import { useTranslation } from "react-i18next";

export default function useTranslationWithCapitalization() {

    const { t } = useTranslation()
    const translateAndCapitalize = (string) => {
        const trnslatedString = t(string);

        const firstLetter = trnslatedString.charAt(0)

        const firstLetterCap = firstLetter.toUpperCase()

        const remainingLetters = trnslatedString.slice(1)

        return firstLetterCap + remainingLetters
    }

    return translateAndCapitalize
}