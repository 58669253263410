import {
  API_KEY_USER_FIRST_NAME,
  API_KEY_USER_LAST_NAME,
  API_KEY_USER_MIDDLE_NAME,
  API_KEY_USER_CITY,
  API_KEY_USER_COMPANY,
  API_KEY_USER_MAIL,
  API_KEY_USER_PHONE,
  API_KEY_USER_PHOTO,
  API_KEY_USER_POSITION_COMPANY,
  API_KEY_USER_WEB_SITE,
  API_KEY_USER_EVENT_FIELD,
  API_KEY_ASSERT_PRIVACY_POLICY,
  API_KEY_AGREE_MAILING_INVITATION,
} from 'api/const';
import useTranslationWithCapitalization from 'utils/useTranslationWithCapitalization';
import { string, number, object, boolean } from 'yup';


// init user data
const initialUserFormData = {
  // event
  [API_KEY_USER_EVENT_FIELD]: null,
  // name
  [API_KEY_USER_FIRST_NAME]: null,
  [API_KEY_USER_LAST_NAME]: null,
  [API_KEY_USER_MIDDLE_NAME]: null,
  // data
  [API_KEY_USER_MAIL]: null,
  [API_KEY_USER_PHONE]: null,
  [API_KEY_USER_COMPANY]: null,
  [API_KEY_USER_POSITION_COMPANY]: null,
  [API_KEY_USER_WEB_SITE]: null,
  [API_KEY_USER_CITY]: null,
  // photo
  [API_KEY_USER_PHOTO]: null,
  // I accept the Privacy Policy.
  [API_KEY_ASSERT_PRIVACY_POLICY]: false,
  // I agree to receive a mailing list with an invitation
  [API_KEY_AGREE_MAILING_INVITATION]: false,
};

export const useInitialUserFormData = (dictAdditionalFields = {}) => {
  const dictKeyOnly = Object.fromEntries(Object.keys(dictAdditionalFields).map((key) => [key, ""]))
  return {
    ...initialUserFormData,
    ...dictKeyOnly,
  }
}

const _lableDictAdditionalFields = (dictAdditionalFields = {}) => {
  const d = Object.fromEntries(Object.keys(dictAdditionalFields).map((key) => [key, key]))
  return d
}

export const useLabelUserFormData = (dictAdditionalFields = {}) => {
  const tc = useTranslationWithCapitalization()
  const text_assert_privacy_policy = tc("i agree with the privacy policy");
  const text_agree_mailing_invitation = tc("i agree to receive the newsletter");
  const labelUserFormData = {
    // name
    [API_KEY_USER_FIRST_NAME]: tc("first name"),
    [API_KEY_USER_LAST_NAME]: tc("last name"),
    [API_KEY_USER_MIDDLE_NAME]: tc("middle name"),
    // data
    [API_KEY_USER_MAIL]: tc("email"),
    [API_KEY_USER_PHONE]: tc("phone"),
    [API_KEY_USER_COMPANY]: tc("company"),
    [API_KEY_USER_POSITION_COMPANY]: tc("company position"),
    [API_KEY_USER_WEB_SITE]: tc("website"),
    [API_KEY_USER_CITY]: tc("city"),
    // I accept the Privacy Policy
    [API_KEY_ASSERT_PRIVACY_POLICY]: text_assert_privacy_policy,
    [API_KEY_AGREE_MAILING_INVITATION]: text_agree_mailing_invitation,
    ..._lableDictAdditionalFields(dictAdditionalFields),
  }

  return labelUserFormData;
}

// const regCharOnly = /^([^0-9|^\\~!"#$%&'()*+,\\\-./:;<=>?@[\]^_`{|}|^\n\t\s]*)$/
const regCharOnlyAndDashAndSpace = /^([^0-9|^\\~!"#$%&'()*+,\\./:;<=>?@[\]^_`{|}|^\n\t]*)$/
const regPhone = /^\+?1?\d{8,15}$/

const _schemaDictAdditionalFields = (dictAdditionalFields = {}) => {
  let d = {}
  const tc = useTranslationWithCapitalization()
  const textRequiredFields = tc("required field");
  for (let key of Object.keys(dictAdditionalFields)) {
    d[key] = string().typeError(textRequiredFields).required(textRequiredFields)
  }
  return d
}

export const useSchemaUserFormData = (dictAdditionalFields = {}) => {
  const tc = useTranslationWithCapitalization()

  const errInvalidChar = tc("must not contain invalid characters")
  const errEmail = tc("enter the correct email address")
  const errPhone = tc("enter the phone number correctly")
  const schemaUserFormData = object().shape({
    // event
    [API_KEY_USER_EVENT_FIELD]: number(),
    // name
    [API_KEY_USER_FIRST_NAME]: string()
      .matches(regCharOnlyAndDashAndSpace, errInvalidChar)
      .nullable(true),
    [API_KEY_USER_LAST_NAME]: string()
      .matches(regCharOnlyAndDashAndSpace, errInvalidChar)
      .nullable(true),
    [API_KEY_USER_MIDDLE_NAME]: string()
      .matches(regCharOnlyAndDashAndSpace, errInvalidChar)
      .nullable(true),
    // data
    [API_KEY_USER_MAIL]: string()
      .email(errEmail)
      .nullable(true),
    [API_KEY_USER_PHONE]: string()
      .matches(regPhone, errPhone)
      .nullable(true),
    [API_KEY_USER_COMPANY]: string()
      .nullable(true),
    [API_KEY_USER_POSITION_COMPANY]: string()
      .nullable(true),
    [API_KEY_USER_WEB_SITE]: string()
      .nullable(true),
    [API_KEY_USER_CITY]: string()
      .nullable(true),
    [API_KEY_ASSERT_PRIVACY_POLICY]: boolean().required("").oneOf([true], ""),
    [API_KEY_AGREE_MAILING_INVITATION]: boolean().required(""),
    ..._schemaDictAdditionalFields(dictAdditionalFields),
  }).required();

  return schemaUserFormData;
}
