import { Button, styled } from '@mui/material';
import { usePrimaryColor } from './usePrimaryColor';


export const StyledButton = styled(Button)(({ primaryColor }) => ({
    backgroundColor: primaryColor,
    '&:hover': {
      backgroundColor: primaryColor,
    },
    "&:active": {
        backgroundColor: primaryColor
    }
}));

export const useActualButton = () => {

    const primaryColor = usePrimaryColor()
    const ActualButton = primaryColor ? StyledButton : Button
    return ActualButton
}

