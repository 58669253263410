export const BASE_URL = "https://sintechreg.ru";
export const API_URL = BASE_URL + "/api/v1/";
export const API_URL_WIDGET_USER = "user/widget_api/"

// !it is necessary to correct the same in index.html
export const WIDGET_API_ID_NAME = "sintechreg-widget-api"
export const WIDGET_CLASS_NAME_BUTTON = "sintechreg-widget-button"
export const WIDGET_CLASS_NAME_TEXT_FIELD = "sintechreg-widget-text-field"
export const WIDGET_CLASS_NAME_CHECK_BOX = "sintechreg-widget-check-box"
export const WIDGET_CLASS_NAME_TEXT_CHECK_BOX = "sintechreg-widget-text-check-box"
export const WIDGET_CLASS_NAME_TEXT_MESSAGE = "sintechreg-widget-text-message"
export const WIDGET_CLASS_NAME_TEXT_END_REGISTRATION = "sintechreg-widget-text-end-registration"
// styling
export const WIDGET_CLASS_NAME_PRIMARY_COLOR = "primary_color"
// is demo
export const WIDGET_CLASS_NAME_DEMO = "demo"
// text end registration
export const API_KEY_TEXT_END_REGISTRATION = "text_end_registration"
// off button again registration
export const API_KEY_OFF_BUTTON_AGAIN_REGISTRATION = "off_button_again_registration"

export const API_KEY_USER_ID = "id"
export const API_KEY_USER_EVENT_FIELD = "event"
export const API_KEY_LANG = "lang"
// field
export const API_KEY_USER_FIRST_NAME = "first_name"
export const API_KEY_USER_LAST_NAME = "last_name"
export const API_KEY_USER_MIDDLE_NAME = "middle_name"
// info
export const API_KEY_USER_PHONE = "phone"
export const API_KEY_USER_MAIL = "mail"
export const API_KEY_USER_CITY = "city"
export const API_KEY_USER_COMPANY = "company"
export const API_KEY_USER_POSITION_COMPANY = "position_company"
export const API_KEY_USER_WEB_SITE = "web_site"
// additional fields
export const API_KEY_USER_ADDITIONAL_FIELDS = "additional_fields"
// photo
export const API_KEY_USER_PHOTO = "photo"
// I accept the Privacy Policy
export const API_KEY_ASSERT_PRIVACY_POLICY = "assert_privacy_policy"
// I agree to receive a mailing list with an invitation
export const API_KEY_AGREE_MAILING_INVITATION = "agree_mailing_invitation"

// enum view field
export const API_ENUM_VIEW_WIDGET = {
    REQUIRED: "required",
    NOREQUIRED: "norequired",
    OPTIONAL: "optional",
    EMPTY: "",
}
// enum i18n
export const API_ENUM_I18N = {
    RU: "ru",
    EN: "en",
}

export const API_KEY_VIEW_WIDGET_IS_VIEW = "is_view"
export const API_KEY_VIEW_WIDGET_IS_REQUIRED = "is_required"
export const API_KEY_STRUCT_VIEW_WIDGET = {
    getIsView: props => props[API_KEY_VIEW_WIDGET_IS_VIEW],
    getIsRequired: props => props[API_KEY_VIEW_WIDGET_IS_REQUIRED],
}


const isExistsKey = (key) => key !== undefined
const isNumber = (key) => /^[0-9]*$/.test(key);
export const API_WIDGET_API_KEY = "api_key"
export const API_DATASET_STRUCT = {
    isExistsApiKey: props => isExistsKey(props[API_WIDGET_API_KEY]),
    getApiKey: props => props[API_WIDGET_API_KEY],
    isExistsEventID: props => isExistsKey(props[API_KEY_USER_EVENT_FIELD]) && isNumber(props[API_KEY_USER_EVENT_FIELD]),
    getEventID: props => props[API_KEY_USER_EVENT_FIELD],
    getPrimaryColor: props => props[WIDGET_CLASS_NAME_PRIMARY_COLOR],
    getIsDemo: props => isExistsKey(props[WIDGET_CLASS_NAME_DEMO]),
    getLang: props => props[API_KEY_LANG],

    // last name
    getIsViewLastName: props => true,
    getIsRequaredLastName: props => true,
    // first name
    getIsViewFirstName: props => true,
    getIsRequaredFirstName: props => true,
    // middle name
    getIsViewMiddleName: props => API_KEY_STRUCT_VIEW_WIDGET.getIsView(props[API_KEY_USER_MIDDLE_NAME]),
    getIsRequaredMiddleName: props => API_KEY_STRUCT_VIEW_WIDGET.getIsRequired(props[API_KEY_USER_MIDDLE_NAME]),
    // mail
    getIsViewMail: props => API_KEY_STRUCT_VIEW_WIDGET.getIsView(props[API_KEY_USER_MAIL]),
    getIsRequaredMail: props => API_KEY_STRUCT_VIEW_WIDGET.getIsRequired(props[API_KEY_USER_MAIL]),
    // phone
    getIsViewPhone: props => API_KEY_STRUCT_VIEW_WIDGET.getIsView(props[API_KEY_USER_PHONE]),
    getIsRequaredPhone: props => API_KEY_STRUCT_VIEW_WIDGET.getIsRequired(props[API_KEY_USER_PHONE]),
    // city
    getIsViewCity: props => API_KEY_STRUCT_VIEW_WIDGET.getIsView(props[API_KEY_USER_CITY]),
    getIsRequaredCity: props => API_KEY_STRUCT_VIEW_WIDGET.getIsRequired(props[API_KEY_USER_CITY]),
    // company
    getIsViewCompany: props => API_KEY_STRUCT_VIEW_WIDGET.getIsView(props[API_KEY_USER_COMPANY]),
    getIsRequaredCompany: props => API_KEY_STRUCT_VIEW_WIDGET.getIsRequired(props[API_KEY_USER_COMPANY]),
    // position company
    getIsViewPositionCompany: props => API_KEY_STRUCT_VIEW_WIDGET.getIsView(props[API_KEY_USER_POSITION_COMPANY]),
    getIsRequaredPositionCompany: props => API_KEY_STRUCT_VIEW_WIDGET.getIsRequired(props[API_KEY_USER_POSITION_COMPANY]),
    // web site
    getIsViewWebSite: props => API_KEY_STRUCT_VIEW_WIDGET.getIsView(props[API_KEY_USER_WEB_SITE]),
    getIsRequaredWebSite: props => API_KEY_STRUCT_VIEW_WIDGET.getIsRequired(props[API_KEY_USER_WEB_SITE]),
}

export const API_STATUS_REGISTRATION = {
    REGISTERED: "REGISTERED",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    CLOSE: "CLOSE",
}
