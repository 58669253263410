import {
    API_DATASET_STRUCT,
    API_KEY_AGREE_MAILING_INVITATION,
    API_KEY_USER_CITY,
    API_KEY_USER_COMPANY,
    API_KEY_USER_FIRST_NAME,
    API_KEY_USER_LAST_NAME,
    API_KEY_USER_MAIL,
    API_KEY_USER_MIDDLE_NAME,
    API_KEY_USER_PHONE,
    API_KEY_USER_POSITION_COMPANY,
    API_KEY_USER_WEB_SITE
} from 'api/const';
import useTranslationWithCapitalization from 'utils/useTranslationWithCapitalization';
import { initRequiredField } from './initViewRequiredField';

const _updateFields = (key, schema, isUpdate) => {
    const tc = useTranslationWithCapitalization()
    const textRequiredFields = tc("required field");
    if (isUpdate)
        schema.fields[key] = schema.fields[key].required(textRequiredFields);
    else
        schema.fields[key] = schema.fields[key].notRequired()
    return;
}

const _updateCheckBoxFields = (key, schema, isUpdate) => {
    if (isUpdate)
        schema.fields[key] = schema.fields[key].required().oneOf([true], "");
    else
        schema.fields[key] = schema.fields[key].notRequired().oneOf([false, true], "");
    return;
}

export function updateRequiredField(schema, dataSetMap, dictAdditionalFields = {}) {
    // last name
    _updateFields(API_KEY_USER_LAST_NAME, schema, API_DATASET_STRUCT.getIsRequaredLastName(dataSetMap));
    // first name
    _updateFields(API_KEY_USER_FIRST_NAME, schema, API_DATASET_STRUCT.getIsRequaredFirstName(dataSetMap));
    // middle name
    _updateFields(API_KEY_USER_MIDDLE_NAME, schema, API_DATASET_STRUCT.getIsRequaredMiddleName(dataSetMap));
    // mail
    _updateFields(API_KEY_USER_MAIL, schema, API_DATASET_STRUCT.getIsRequaredMail(dataSetMap));
    // phone
    _updateFields(API_KEY_USER_PHONE, schema, API_DATASET_STRUCT.getIsRequaredPhone(dataSetMap));
    // city
    _updateFields(API_KEY_USER_CITY, schema, API_DATASET_STRUCT.getIsRequaredCity(dataSetMap));
    // company
    _updateFields(API_KEY_USER_COMPANY, schema, API_DATASET_STRUCT.getIsRequaredCompany(dataSetMap));
    // position
    _updateFields(API_KEY_USER_POSITION_COMPANY, schema, API_DATASET_STRUCT.getIsRequaredPositionCompany(dataSetMap));
    // web site
    _updateFields(API_KEY_USER_WEB_SITE, schema, API_DATASET_STRUCT.getIsRequaredWebSite(dataSetMap));
    // additional fields
    Object.entries(dictAdditionalFields).forEach(([key, value]) => {
        _updateFields(key, schema, initRequiredField(value));
    })
    // check box mailing
    _updateCheckBoxFields(API_KEY_AGREE_MAILING_INVITATION, schema, API_DATASET_STRUCT.getIsRequaredMail(dataSetMap));
    return schema;
}
