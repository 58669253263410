import React, { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
    API_DATASET_STRUCT,
    API_KEY_USER_ADDITIONAL_FIELDS,
    API_KEY_USER_EVENT_FIELD,
    API_STATUS_REGISTRATION,
} from "api/const";
import DialogUserItem from 'form/DialogUserItem';
import { useInitialUserFormData, useLabelUserFormData, useSchemaUserFormData } from 'form/UsersFormData';
import { updateRequiredField } from 'utils/updateRequiredField';
import readDataSetMap from 'utils/readDataSetMap';
import useTranslationWithCapitalization from 'utils/useTranslationWithCapitalization';
import ErrorRegistration from 'status/ErrorRegistration';
import { convertUserAdditionalFieldsToDict } from 'utils/convertAdditionalFields';
import useSubmitUserRegistration from 'hooks/useSubmitUserRegistration';
import EndRegistration from 'EndRegistration';

export const WidgetContext = createContext(null)

function WidgetRegistrationApi() {

    // extract dataset map
    const dataSetMap = readDataSetMap()

    // i18n
    const { i18n } = useTranslation();
    const lang = API_DATASET_STRUCT.getLang(dataSetMap);
    // FIX: update lang ONLY if need!
    if (i18n.language !== lang)
        i18n.changeLanguage(lang);

    const tc = useTranslationWithCapitalization();

    const dictAdditionalFields = convertUserAdditionalFieldsToDict(dataSetMap[API_KEY_USER_ADDITIONAL_FIELDS]);

    // init data
    const initialData = {
        ...useInitialUserFormData(dictAdditionalFields),
        [API_KEY_USER_EVENT_FIELD]: API_DATASET_STRUCT.getEventID(dataSetMap),
    }
    // form
    const [userFormData, setUserFormData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);

    /// status registration
    const [statusRegistration, setStatusRegistration] = useState(API_STATUS_REGISTRATION.REGISTERED);
    const onClickAgainRegistration = () => setStatusRegistration(API_STATUS_REGISTRATION.REGISTERED);

    const onStartLoading = () => setIsLoading(true);
    const onEndLoading = () => {
        setIsLoading(false)
        setUserFormData({ ...initialData })
    }

    const onSubmit = useSubmitUserRegistration(
        dataSetMap, dictAdditionalFields,
        onStartLoading, onEndLoading,
        setStatusRegistration);

    // form data
    const schemaUserFormData = useSchemaUserFormData(dictAdditionalFields);
    updateRequiredField(schemaUserFormData, dataSetMap, dictAdditionalFields)
    const labelUserFormData = useLabelUserFormData(dictAdditionalFields);

    const errNoApiKey = tc("API KEY is missing");
    const errNoEventID = tc("correct event ID is missing");

    // check exists API-KEY
    if (!API_DATASET_STRUCT.isExistsApiKey(dataSetMap))
        return (<ErrorRegistration text={errNoApiKey} />);
    // check exists EVENT-ID
    if (!API_DATASET_STRUCT.isExistsEventID(dataSetMap))
        return <ErrorRegistration text={errNoEventID} />;

    return (
        <div>
            <WidgetContext.Provider value={dataSetMap}>
                {
                    statusRegistration === API_STATUS_REGISTRATION.REGISTERED ?
                        <DialogUserItem
                            initialData={userFormData}
                            labelUserFormData={labelUserFormData}
                            schemaResolver={schemaUserFormData}

                            onSubmit={onSubmit}

                            dataSetMap={dataSetMap}
                            dictAdditionalFields={dictAdditionalFields}

                            isLoading={isLoading}
                        />
                        :
                        <EndRegistration
                            statusRegistration={statusRegistration}
                            onClick={onClickAgainRegistration}
                            dataSetMap={dataSetMap}
                        />
                }
            </WidgetContext.Provider>
        </div>
    )
}

export default WidgetRegistrationApi