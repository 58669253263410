import { Grid, Typography } from '@mui/material'
import { WIDGET_CLASS_NAME_TEXT_END_REGISTRATION } from 'api/const'
import React from 'react'

function TypographyEndRegisrtation({ textEndRegistration }) {
    return (
        <Grid item xs={12}>
            <Typography
                className={WIDGET_CLASS_NAME_TEXT_END_REGISTRATION}
                align="center"
            >
                {textEndRegistration}
            </Typography>
        </Grid>
    )
}

export default TypographyEndRegisrtation