import { useContext } from 'react'
import {
    API_DATASET_STRUCT,
} from 'api/const';
import { WidgetContext } from 'WidgetRegistrationApi';


export const usePrimaryColor = () => {
    const widjetContext = useContext(WidgetContext)

    return API_DATASET_STRUCT.getPrimaryColor(widjetContext)
}
