import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { WIDGET_API_ID_NAME } from 'api/const';
import 'i18n/config';

const root = ReactDOM.createRoot(document.getElementById(WIDGET_API_ID_NAME));
root.render(
  <App />
);

