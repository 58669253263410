import axios from "axios";
import { API_DATASET_STRUCT, API_URL } from "api/const";

const AxiosInstance = (dataSetMap) => {
    const axiosInstance = axios.create({
        baseURL: API_URL,
        headers: {
            "content-type": "application/json",
            "x-api-key": `${API_DATASET_STRUCT.getApiKey(dataSetMap)}`,
        },
        withCredentials: true,
        xsrfCookieName: "csrftoken",
        xsrfHeaderName: "X-CSRFToken",
    })
    return axiosInstance;
}

export default AxiosInstance;