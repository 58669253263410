import AxiosInstance from "api/AxiosInstance";
import {
    API_DATASET_STRUCT,
    API_KEY_AGREE_MAILING_INVITATION,
    API_KEY_ASSERT_PRIVACY_POLICY,
    API_KEY_USER_ADDITIONAL_FIELDS,
    API_STATUS_REGISTRATION,
    API_URL_WIDGET_USER,
} from "api/const";
import { convertDictToUserAdditionalFields } from "utils/convertAdditionalFields";

function useSubmitUserRegistration(
    dataSetMap, dictAdditionalFields,
    onStartLoading, onEndLoading,
    setStatusRegistration) {

    const onSubmit = async (item) => {
        // no need in backend
        delete item[API_KEY_ASSERT_PRIVACY_POLICY]
        delete item[API_KEY_AGREE_MAILING_INVITATION]

        //
        let d = {}
        for (let key_additional of Object.keys(dictAdditionalFields)) {
            d[key_additional] = item[key_additional];
            delete item[key_additional];
        }

        item = {
            ...item,
            [API_KEY_USER_ADDITIONAL_FIELDS]: convertDictToUserAdditionalFields(d)
        }

        // imitate sucesfull registration on form demo
        const isFormDisabled = API_DATASET_STRUCT.getIsDemo(dataSetMap)
        if (isFormDisabled) {
            onStartLoading();
            setStatusRegistration(API_STATUS_REGISTRATION.SUCCESS)
            onEndLoading();
            return;
        }

        const api = AxiosInstance(dataSetMap);
        onStartLoading();
        await api.post(API_URL_WIDGET_USER, item)
            .then(() => setStatusRegistration(API_STATUS_REGISTRATION.SUCCESS))
            .catch((err) => {
                if (err.response && err.response["status"] === 477)
                    setStatusRegistration(API_STATUS_REGISTRATION.CLOSE);
                else
                    setStatusRegistration(API_STATUS_REGISTRATION.ERROR);
            })
            .finally(() => {
                onEndLoading();
            })
    }
    return onSubmit
}

export default useSubmitUserRegistration