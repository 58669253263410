import { API_ENUM_I18N } from "api/const";


export function getLang(lang) {
    if (lang === null || lang === undefined)
        return API_ENUM_I18N.RU;

    switch (lang) {
        case API_ENUM_I18N.RU:
        case API_ENUM_I18N.EN:
            return lang
        default:
            return API_ENUM_I18N.RU
    }
}