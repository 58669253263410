import React from 'react'
import useTranslationWithCapitalization from 'utils/useTranslationWithCapitalization';
import StatusRegistration from './StatusRegistration';

function SuccessRegistration() {
    const tc = useTranslationWithCapitalization();
    const message = tc("successfully registered") + "!"
    return (
        <StatusRegistration color={"green"} text={message} />
    )
}

export default SuccessRegistration