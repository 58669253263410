import {
    API_KEY_LANG,
    API_KEY_OFF_BUTTON_AGAIN_REGISTRATION,
    API_KEY_TEXT_END_REGISTRATION,
    API_KEY_USER_ADDITIONAL_FIELDS,
    API_KEY_USER_CITY,
    API_KEY_USER_COMPANY,
    API_KEY_USER_EVENT_FIELD,
    API_KEY_USER_FIRST_NAME,
    API_KEY_USER_LAST_NAME,
    API_KEY_USER_MAIL,
    API_KEY_USER_MIDDLE_NAME,
    API_KEY_USER_PHONE,
    API_KEY_USER_POSITION_COMPANY,
    API_KEY_USER_WEB_SITE,
    API_WIDGET_API_KEY,
    WIDGET_API_ID_NAME,
    WIDGET_CLASS_NAME_DEMO,
    WIDGET_CLASS_NAME_PRIMARY_COLOR,
} from "api/const"
import { initViewRequiredField } from "./initViewRequiredField"
import { getValidStringOrNull } from "./getValidStringOrNull"
import { getBool } from "./getBool"
import { getLang } from "./getLang"


export default function readDataSetMap() {
    const widget = document.getElementById(WIDGET_API_ID_NAME)

    return {
        [API_WIDGET_API_KEY]: widget.dataset[API_WIDGET_API_KEY],
        [API_KEY_USER_EVENT_FIELD]: widget.dataset[API_KEY_USER_EVENT_FIELD],

        [API_KEY_USER_LAST_NAME]: initViewRequiredField(widget.dataset[API_KEY_USER_LAST_NAME]),
        [API_KEY_USER_FIRST_NAME]: initViewRequiredField(widget.dataset[API_KEY_USER_FIRST_NAME]),
        [API_KEY_USER_MIDDLE_NAME]: initViewRequiredField(widget.dataset[API_KEY_USER_MIDDLE_NAME]),
        [API_KEY_USER_MAIL]: initViewRequiredField(widget.dataset[API_KEY_USER_MAIL]),
        [API_KEY_USER_PHONE]: initViewRequiredField(widget.dataset[API_KEY_USER_PHONE]),
        [API_KEY_USER_CITY]: initViewRequiredField(widget.dataset[API_KEY_USER_CITY]),
        [API_KEY_USER_COMPANY]: initViewRequiredField(widget.dataset[API_KEY_USER_COMPANY]),
        [API_KEY_USER_POSITION_COMPANY]: initViewRequiredField(widget.dataset[API_KEY_USER_POSITION_COMPANY]),
        [API_KEY_USER_WEB_SITE]: initViewRequiredField(widget.dataset[API_KEY_USER_WEB_SITE]),

        [API_KEY_TEXT_END_REGISTRATION]: getValidStringOrNull(widget.dataset[API_KEY_TEXT_END_REGISTRATION]),
        [API_KEY_OFF_BUTTON_AGAIN_REGISTRATION]: getBool(widget.dataset[API_KEY_OFF_BUTTON_AGAIN_REGISTRATION]),

        [API_KEY_LANG]: getLang(widget.dataset[API_KEY_LANG]),

        [API_KEY_USER_ADDITIONAL_FIELDS]: widget.dataset[API_KEY_USER_ADDITIONAL_FIELDS],

        [WIDGET_CLASS_NAME_PRIMARY_COLOR]: widget.dataset[WIDGET_CLASS_NAME_PRIMARY_COLOR],
        [WIDGET_CLASS_NAME_DEMO]: widget.dataset[WIDGET_CLASS_NAME_DEMO],
    }
}