import WidgetRegistrationApi from "./WidgetRegistrationApi";

function App() {
  // whit load padge
  return (
    <WidgetRegistrationApi />
  );
}

export default App;
