import React from 'react'
import { Grid, Typography } from '@mui/material'
import { WIDGET_CLASS_NAME_TEXT_MESSAGE } from 'api/const'

function StatusRegistration({ color, text }) {
    return (
        <Grid container sx={{ my: 1 }}>
            <Grid item
                xs={12}
                sx={{
                    backgroundColor: color,
                    color: "white",
                    mt: 1,
                    borderRadius: "5px",
                }}
            >
                <Typography
                    className={WIDGET_CLASS_NAME_TEXT_MESSAGE}
                    sx={{
                        py: 1,
                        fontWeight: 'bold',
                    }}
                    align='center'
                >
                    {text}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default StatusRegistration