import React from 'react'
// redux
// react-hook-form
import { useController } from 'react-hook-form'
// MUI
import {
    Grid,
    Checkbox,
    Typography,
} from '@mui/material'
import StackRow from 'form/StackRow';
import { WIDGET_CLASS_NAME_CHECK_BOX, WIDGET_CLASS_NAME_TEXT_CHECK_BOX } from 'api/const';
import { red } from '@mui/material/colors';
import { usePrimaryColor } from 'syled/usePrimaryColor';

function InputCheckBox({
    nameField,
    control,
    labels,
    isDisabled = false,
    isViewField = true,
    sx = { xs: 12 },
}) {
    const {
        field: { onChange, onBlur, name, value, ref },
        fieldState: { error },
    } = useController({
        name: nameField,
        control,
    });

    const label = labels ? labels[nameField] || "" : "";
    const colorRed = red[600];

    const primaryColor = usePrimaryColor()

    const color = !!error ? colorRed : primaryColor;
    if (!isViewField)
        return null;

    return (
        <Grid item {...sx} >
            <StackRow>
                <Checkbox
                    className={WIDGET_CLASS_NAME_CHECK_BOX}
                    checked={value}
                    required
                    disabled={isDisabled}
                    onChange={onChange}
                    onBlur={onBlur} // notify when input is touched/blur
                    value={value || ""} // input value
                    name={name} // send down the input name
                    inputRef={ref} // send input ref, so we can focus on input when error appear

                    sx={{
                        color: color,
                        '&.Mui-checked': {
                            color: color,
                        },
                    }}
                />
                <Typography
                    className={WIDGET_CLASS_NAME_TEXT_CHECK_BOX}
                    variant="body2"
                    color={!!error ? colorRed : null}
                >
                    {label + "*"}
                </Typography>
            </StackRow>
        </Grid>
    );
}

export default InputCheckBox