export const convertUserAdditionalFieldsToDict = (additional_fields) => {
    let d = {}

    if (null === additional_fields || undefined === additional_fields)
        return d;

    const arrAdditionalFields = additional_fields.split("&&");

    for (let i in arrAdditionalFields) {
        const line = arrAdditionalFields[i];

        if (!line) continue;

        const arrLine = line.split(":")

        const key = arrLine[0]
        const value = arrLine.slice(1).join(":")

        // d[key] = value === "" ? null : value;
        d[key] = value;
    }
    return d;
}

export const convertDictToUserAdditionalFields = (d) => {
    let s = ""

    if (null === d)
        return s

    Object.entries(d).forEach(([key, value]) => {
        s += `${key}:${value}&&`
    })

    return s
}
