import { useCallback, useEffect } from 'react'

function useEffectUpdateFormData(initialData, setValue, getValues, clearErrors, funcAfterLoop = null) {
    // callback
    const callback = useCallback(() => {
        if (initialData) {
            Object.entries(initialData).forEach(([key, value]) => {
                // drop no need data
                if (getValues(key) === undefined) return;

                setValue(key, value)
            });
            // check valid optional fields
            if (null !== funcAfterLoop)
                funcAfterLoop();
            clearErrors();
        }
    }, [initialData, setValue, getValues, clearErrors]); // eslint-disable-line

    // effect
    useEffect(() => {
        callback();
    }, [callback])
}

export default useEffectUpdateFormData
