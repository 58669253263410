import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid } from '@mui/material';
import {
    API_DATASET_STRUCT,
    API_KEY_AGREE_MAILING_INVITATION,
    API_KEY_ASSERT_PRIVACY_POLICY,
    API_KEY_USER_CITY,
    API_KEY_USER_COMPANY,
    API_KEY_USER_FIRST_NAME,
    API_KEY_USER_LAST_NAME,
    API_KEY_USER_MAIL,
    API_KEY_USER_MIDDLE_NAME,
    API_KEY_USER_PHONE,
    API_KEY_USER_POSITION_COMPANY,
    API_KEY_USER_WEB_SITE,
    WIDGET_CLASS_NAME_BUTTON,
} from 'api/const';
import InputPhoneField from 'form/InputPhoneField';
import InputTextField from 'form/InputTextField';
import useEffectUpdateFormData from '../utils/useEffectUpdateFormData';
import useTranslationWithCapitalization from 'utils/useTranslationWithCapitalization';
import InputCheckBox from './InputCheckBox';
import { useActualButton } from 'syled/StyledButton';
import { usePrimaryColor } from 'syled/usePrimaryColor';
import { initRequiredField } from 'utils/initViewRequiredField';

function DialogUserItem({
    // data form
    initialData,
    labelUserFormData,
    schemaResolver,
    // data-set
    dataSetMap,
    dictAdditionalFields,
    // call function
    onFocus,
    onSubmit,
    // flag loading
    isLoading,
}) {
    const { handleSubmit, control, setValue, getValues, clearErrors } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: initialData,
        resolver: yupResolver(schemaResolver),
    })
    useEffectUpdateFormData(initialData, setValue, getValues, clearErrors)

    const tc = useTranslationWithCapitalization();
    const nameButton = tc("register")

    const ActualButton = useActualButton()
    const primaryColor = usePrimaryColor()

    return (
        <Grid
            container
            spacing={2}
            sx={{ my: 1 }}
        >
            {/* name */}
            <InputTextField
                isViewField={API_DATASET_STRUCT.getIsViewLastName(dataSetMap)}
                isRequaredField={API_DATASET_STRUCT.getIsRequaredLastName(dataSetMap)}
                nameField={API_KEY_USER_LAST_NAME}
                control={control}
                labels={labelUserFormData}
                isNormalCase={true}
                isDisabled={isLoading}
            />
            <InputTextField
                isViewField={API_DATASET_STRUCT.getIsViewFirstName(dataSetMap)}
                isRequaredField={API_DATASET_STRUCT.getIsRequaredFirstName(dataSetMap)}
                nameField={API_KEY_USER_FIRST_NAME}
                control={control}
                labels={labelUserFormData}
                isNormalCase={true}
                isDisabled={isLoading}
            />
            <InputTextField
                isViewField={API_DATASET_STRUCT.getIsViewMiddleName(dataSetMap)}
                isRequaredField={API_DATASET_STRUCT.getIsRequaredMiddleName(dataSetMap)}
                nameField={API_KEY_USER_MIDDLE_NAME}
                control={control}
                labels={labelUserFormData}
                isNormalCase={true}
                isDisabled={isLoading}
            />
            {/* data */}
            <InputTextField
                isViewField={API_DATASET_STRUCT.getIsViewMail(dataSetMap)}
                isRequaredField={API_DATASET_STRUCT.getIsRequaredMail(dataSetMap)}
                nameField={API_KEY_USER_MAIL}
                control={control}
                labels={labelUserFormData}
                isDisabled={isLoading}
            />
            <InputTextField
                isViewField={API_DATASET_STRUCT.getIsViewCompany(dataSetMap)}
                isRequaredField={API_DATASET_STRUCT.getIsRequaredCompany(dataSetMap)}
                nameField={API_KEY_USER_COMPANY}
                control={control}
                labels={labelUserFormData}
                isNormalCase={true}
                isDisabled={isLoading}
            />
            <InputTextField
                isViewField={API_DATASET_STRUCT.getIsViewPositionCompany(dataSetMap)}
                isRequaredField={API_DATASET_STRUCT.getIsRequaredPositionCompany(dataSetMap)}
                nameField={API_KEY_USER_POSITION_COMPANY}
                control={control}
                labels={labelUserFormData}
                isDisabled={isLoading}
            />
            <InputTextField
                isViewField={API_DATASET_STRUCT.getIsViewCity(dataSetMap)}
                isRequaredField={API_DATASET_STRUCT.getIsRequaredCity(dataSetMap)}
                nameField={API_KEY_USER_CITY}
                control={control}
                labels={labelUserFormData}
                isDisabled={isLoading}
            />
            <InputPhoneField
                isViewField={API_DATASET_STRUCT.getIsViewPhone(dataSetMap)}
                isRequaredField={API_DATASET_STRUCT.getIsRequaredPhone(dataSetMap)}
                nameField={API_KEY_USER_PHONE}
                control={control}
                labels={labelUserFormData}
                isDisabled={isLoading}
            />
            <InputTextField
                isViewField={API_DATASET_STRUCT.getIsViewWebSite(dataSetMap)}
                isRequaredField={API_DATASET_STRUCT.getIsRequaredWebSite(dataSetMap)}
                nameField={API_KEY_USER_WEB_SITE}
                control={control}
                labels={labelUserFormData}
                isDisabled={isLoading}
            />
            {/* additional fields */}
            {
                Object.entries(dictAdditionalFields).map(([key, value]) => {
                    return (
                        <InputTextField
                            key={key}
                            isNormalCase={true}
                            isViewField={true}
                            isRequaredField={initRequiredField(value)}
                            nameField={key}
                            control={control}
                            onFocus={onFocus}
                            labels={labelUserFormData}
                            isDisabled={isLoading}
                        />
                    )
                })
            }
            <InputCheckBox
                nameField={API_KEY_ASSERT_PRIVACY_POLICY}
                control={control}
                labels={labelUserFormData}
                isDisabled={isLoading}
            />
            <InputCheckBox
                isViewField={API_DATASET_STRUCT.getIsRequaredMail(dataSetMap)}
                nameField={API_KEY_AGREE_MAILING_INVITATION}
                control={control}
                labels={labelUserFormData}
                isDisabled={isLoading}
            />
            <Grid item
                xs={12}
            >
                <ActualButton
                    primaryColor={primaryColor}
                    fullWidth
                    className={WIDGET_CLASS_NAME_BUTTON}
                    variant="contained"
                    color="success"
                    onClick={handleSubmit(onSubmit)}>
                    {isLoading ?
                        <CircularProgress sx={{ color: "#FFFFFF" }} size={25} />
                        : nameButton}
                </ActualButton>
            </Grid>
        </Grid>
    )
}

export default DialogUserItem

